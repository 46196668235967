import { RequestManager, HTTPTransport, Client } from '@open-rpc/client-js';

export const RPCClient = (address, port, protocol) => {
  const portToUse = port ? `:${port}` : '';
  const url = `${protocol}${address}${portToUse}/jsonrpc`;
  let transport = new HTTPTransport(url);
  let client = new Client(new RequestManager([transport]));

  return {
    _url: url,
    async invoke(method, params) {
      return await client.request({
        method,
        params,
      });
    },
  };
};

import React from 'react';
import styled from 'styled-components';

const MainLayoutContainer = styled.div`
  display: flex;
  flex-flow: column;
  background-color: #fafafa;
  height: 100%;
  width: 100%;
  margin: auto 0;
`;

export const MainLayout = (props) => <MainLayoutContainer {...props} />;

const TopContentContainer = styled.div`
  top: 0;
  padding: 0 2.8rem;
  display: flex;
  align-items: left;
  justify-content: space-between;
  border-bottom: 0.1rem solid var(--color-grey-2);
`;

export const TopContent = (props) => <TopContentContainer {...props} />;

const MainContentContainer = styled.div`
  flex-grow : 1;  
  position: relative;
  overflow: hidden;
`;

export const MainContent = (props) => <MainContentContainer {...props} />;


export const Content = styled.div`
  padding: 2.8rem;
  height: 100%;
  flex-grow : 1;  
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`;


export const Box = styled.div`
  flex-wrap: wrap;
  align-items: stretch;
  height: 100%;
  padding-bottom: 80px;
  
  ${(props) => props.outlined && 'outline: 1px solid red;'}
  ${(props) => props.inline && 'display: inline-block;'}
  ${(props) => props.flex && 'display: flex;'}
  ${(props) => props.column && 'flex-direction: column;'}
  ${(props) => props.alignItems && `flex-direction: ${props.alignItems};`}
  ${(props) => props.expanded && 'flex: 1;'}
  ${(props) => props.width && `width: ${props.width};`}
  ${(props) => props.height && `height: ${props.height};`}
  ${(props) => props.maxHeight && `max-height: ${props.maxHeight};`}

  ${(props) => props.mt && `margin-top: ${props.mt};`}
`;

export const TextButtonDiv = styled.div`
  padding: 10px;
  display: inline;
  flex-justify: space-around;
  white-space: nowrap;
  width: 180px;
  margin: 10px;
  border: 1px black dotted;
  border-radius: 0.25em;
  ${props => props.$loading && ' background-color: #ccc; '}
  ${props => props.$playing && ' background-color: #9c9; outline: none;'}
  ${props => props.$me && ' background-color: #f0dddd; outline: none; padding: 0; margin 20px; width: 160px;'}
  &:not(.nodata):hover {
    border: 1px #111 solid; cursor: pointer;
  }

  ${(props) => props.width && `width: ${props.width};`}
  ${(props) => props.nodata && `text-align: center; font-style: italic;`}
  
  &:focus {
    outline: none;
    border-color: grey;
    box-shadow: 0 0 3px grey;
    margin: 1rem;
    padding: 1rem;
  }
`;

export const TextButton = styled.button`
  padding: 0;
  border: 0;
  font-weight: 700;
  cursor: pointer;

  &:focus {
    outline: none;
  }
  
  &:hover {
    text-decoration: underline;
  }
`;


export const Div = styled.div`
  padding: 0.8rem 1.2rem;
  display: inline;
  flex-justify: space-around;
  white-space: nowrap;
  width: 200px;
  margin: 0.5rem 0.6rem;
  border: 1px black dotted;
  border-radius: 0.25em;
  ${props => props.$loading && ' background-color: #ccc; '}
  ${props => props.playing && ' background-color: #9c9;     outline: none;'}
  &:not(.nodata):hover {
    border: 1px #111 solid; cursor: pointer;
  }

  ${(props) => props.width && `width: ${props.width};`}
  ${(props) => props.nodata && `text-align: center; font-style: italic;`}
  
  &:focus {
    outline: none;
    border-color: grey;
    box-shadow: 0 0 3px grey;
  }

  
`;


export const StyledPlayArea = styled.div`
  display: flex;
  flex-direction: column;
  width:100%;
  height:100%;
  margin:  0;
`;

import styled from 'styled-components';

export const Button = styled.button`
  cursor: pointer;
  min-width: 10rem;
  padding: 1rem;
  text-align: center;
  transition: 0.2s ease;

  &:focus {
    outline: none;
  }
  
  &:hover {
    text-decoration: underline;
    border: 1px black solid;
  }
  + button {
    margin-left: 1rem;
  }

  ${({ variant }) => variant === 'warn' && `background: var(--color-red);`}

  &:disabled {
    cursor: default;
    background: #eee;
    color: #ccc;
    opacity: 0.5;
  }
`;

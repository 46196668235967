import { createAtom, makeAutoObservable, entries } from 'mobx';
import { APIClient } from '../api/client';
import { Settings } from './settings';

import * as Tone from 'tone';
import {getCloudServerUrl} from "../utils/utils";
import {WebsocketClient} from "../api/ws-client";


let _snackBarId = 0;

if (process.env.NODE_ENV !== 'production') {
  window._client = APIClient;
}

export class Store {
  constructor() {
    this.user = null;
    this.usersMap = {};
    this.workshops = [];
    this.workshopCode = null;
    this.roomCode = "";
    this.selectedRoom = null;
    this.snackbars = [];
    this.sounds = [];
    this.soundsLoaded = [];
    this.onAir = false;
    this.canChangeOnAirStatus = true;
    this.deviceId = this.readCookie("device_uuid");
    this.csrfToken = null;
    console.log(this.deviceId)


    this.websocketClient = null;

    this._subscribeToAPIClientEvents();
    makeAutoObservable(this);

    this.settings = new Settings();
    this.settings.restore();

    const ws_scheme = window.location.protocol === "https:" ? "wss" : "ws";
    this.socket_ws_path = ws_scheme + "://".concat(this.settings.server.url).concat("/ws/chat/");

    this._settingsAtom = createAtom('settings');
    this.settings.onSave = () => {
      this._settingsAtom.reportChanged();
    };

    this.updateClientSettings();

    this.isStaff = false;

    window.addEventListener('pagehide', (e) => !e.persisted && this.dispose(), false);
  }


  _subscribeToAPIClientEvents() {
    APIClient.on_users_map_change = ({ users_map }) => {
      this.usersMap = users_map;
    };
  }

  updateClientSettings() {
    if (!APIClient._cloud_client || APIClient._cloud_server_url !== this.settings.server.url) {
      let tss = this.settings.server;
      APIClient.init_rpc_client(tss.url, tss.port, tss.protocol);
    }
  }

  get workshopCodeOk() {
    return this.workshopCode !== null;
  }

  set workshopCodeOk(o) {
    this.workshopCode = o;
  }

  get userLoggedIn() {
    return this.user !== null;
  }

  get roomSelected() {
    return this.selectedRoom !== null;
  }

  set deviceIdUpdate(deviceId) {
    this.deviceId = deviceId;
  }

  set csrfTokenUpdate(csrfToken) {
    this.csrfToken = csrfToken;
  }
  get onlineUsers() {
    return entries(this.usersMap).map(([key, user]) => {
      const [country, city] = user.location.split(':');
      return { id: parseInt(key, 10), ...user, country, city };
    });
  }

  connectToRoom(roomId) {
    console.log('connectToRoom', roomId);
    let rooms = this.workshops[this.workshopCode].rooms
    this.selectedRoom = rooms.find(room => room.id === roomId);
    const address = this.socket_ws_path.concat(this.workshopCode).concat("/");
    this.websocketClient = new WebsocketClient(address, this);

    this.sendMessage({
      "command": "join",
      "room": roomId
    });

    let soundsToLoad = this.selectedRoom.sounds;
    for (let i = 0; i < soundsToLoad.length; i++) {
      let soundId = soundsToLoad[i].id;

      console.log(soundsToLoad[i].sound_file);
      let tonePlayer = new Tone.Player({
        url: soundsToLoad[i].sound_file,
        onload: () => this.updateSoundsLoaded(soundId),
        onstop: () => this.soundStopped(soundId),
      }).toDestination();
      let soundInfo = {
        id: soundId,
        soundAudio: tonePlayer,
        loading: true,
        playing: false,
        me: false,
        title:soundsToLoad[i].title,
      }
      this.sounds.push(soundInfo);
    }
    this.onAir = this.selectedRoom.on_air;
  }

  updateSoundsLoaded(i) {
    console.log("soundLoaded: " + i);
    this.soundsLoaded.push({id:i});
    // once all sounds loaded

    let soundsToLoad = this.selectedRoom.sounds;
    let allSoundsLoaded = true;
    for (let i = 0; i < soundsToLoad.length; i++) {
      let soundId = soundsToLoad[i].id;
      let soundLoaded = this.soundsLoaded.find(sound => sound.id === soundId);
      if (!soundLoaded) {
        allSoundsLoaded = false;
        break;
      } else {
        let sound = this.sounds.find(sound => sound.id === soundId)
        sound.loading = false;
      }
    }
    if (allSoundsLoaded) {
      Tone.start();
    }
  }

  soundStopped(i) {
    let sound = this.sounds.find(sound => sound.id === i)
    if (sound.playing) {
      sound.playing = false;
    }
    if (sound.me) {
      sound.me = false;
    }
  }

  async checkWorkshopCode(workshop_code) {
    try {
      const response = await APIClient.check_workshop_code(workshop_code);
      this.workshops = [];

      let workshop = {};
      for (const [k, v] of Object.entries(response)) {
        workshop[k] = v;
      }
      this.workshops[workshop_code] = workshop;
      this.settings.workshops = this.workshops;
      this.settings.save();

      // check how many rooms. if only one, set selected Room
      if (this.workshops[workshop_code].rooms.length === 1) {
        this.selectedRoom = this.workshops[workshop_code].rooms[0];
        this.workshopCodeOk = workshop_code;
        this.connectToRoom(this.selectedRoom.id);
      }

      this.workshopCodeOk = workshop_code;

      return true;
    } catch (err) {
      return false;
    }
  }

  async checkIsStaff() {
    try {
      const response = await APIClient.check_is_staff();
      console.log(response);
      if (response === true) {
        this.isStaff = true;
      }
    } catch (err) {
      this.isStaff = false;
    }
  }

  /*** Snackbar ***/
  showSnackBar(content, { duration = 3000 } = {}) {
    const snackbar = { id: _snackBarId++, content };
    this.snackbars.push(snackbar);
    setTimeout(() => {
      this.snackbars = this.snackbars.filter((_snackbar) => _snackbar.id !== snackbar.id);
    }, duration);
  }

  readCookie(name) {
	  let nameEQ = name + "=";
	  let ca = document.cookie.split(';');
	  for(let i = 0; i < ca.length; i++) {
		  let c = ca[i];
		  while (c.charAt(0)===' ') {
		    c = c.substring(1, c.length);
		  }
  		if (c.indexOf(nameEQ) === 0) {
	  	  return c.substring(nameEQ.length,c.length);
  		}
	  }
	  return null;
  }

  playSound(sId, deviceId) {
    let soundId = parseInt(sId);
    let soundInfo = this.sounds.find(sound => sound.id === soundId);
    let soundLoaded = this.soundsLoaded.find(sound => sound.id === soundId);

    if (deviceId === this.deviceId) {
      let messageDict = {
        "command": "send",
        "room": this.selectedRoom.id,
        "message": "play_" + soundId
      };
      this.sendMessage(messageDict);
      soundInfo.me = true;
    } else {
      soundInfo.playing = true;
    }

    if (soundLoaded) {
      soundInfo.soundAudio.start();
    }
  }

  processMessage(message) {
    // Decode the JSON
    console.log("Got websocket message " + message.data);
    let data = JSON.parse(message.data);

    // Handle errors
    if (data.error) {
      this.showSnackBar(data.error + ", try refreshing the browser" );

      return;
    }

    // Handle joining
    if (data.join) {
      console.log("Joining room " + data.join);
      // this.self.roomId = 0 + data.join;
      // Handle leaving
    } else if (data.leave) {
      console.log("Leaving room " + data.leave);
      // $("#room-" + data.leave).remove();
      // Handle getting a message
    } else if (data.message || data.msg_type !== 0) {
      let ok_msg = "";
      // msg types are defined in chat/settings.py
      // Only for demo purposes is hardcoded, in production scenarios, consider call a service.
      switch (data.msg_type) {
        case 0:
          // Message

          if (data.message.indexOf('play_') !== -1) {
            let soundId = data.message.substring(5);
            console.log("Got play " + soundId + " message");

            // this in this context is the socket, not the store, so use parent
            if (data.device !== this.deviceId) {
              this.playSound(soundId, data.device);
            }
          }

          if (data.message.indexOf('onair_') !== -1) {
            // this in this context is the socket, not the store, so use parent
            if (data.device !== this.deviceId) {
              if (data.message === "onair_true") {
                this.onAir = true;
              } else {
                this.onAir = false;
              }
            }
          }

          break;
        case 1:
          // Warning / Advice messages
          ok_msg = "<div class='contextual-message text-warning'>" + data.message +
              "</div>";
          break;
        case 2:
          // Alert / Danger messages
          ok_msg = "<div class='contextual-message text-danger'>" + data.message +
              "</div>";
          break;
        case 3:
          // "Muted" messages
          ok_msg = "<div class='contextual-message text-muted'>" + data.message +
              "</div>";
          break;
        case 4:
          // User joined room
          ok_msg = "<div class='contextual-message text-muted'>" + data.username +
              " joined the room!" +
              "</div>";
          break;
        case 5:
          // User left room
          // eslint-disable-next-line
          ok_msg = "<div class='contextual-message text-muted'>" + data.username +
              " left the room!" +
              "</div>";
          break;
        default:
          console.log("Unsupported message type!");
          return;
      }
      // msgdiv.append(ok_msg);

      // msgdiv.scrollTop(msgdiv.prop("scrollHeight"));
    } else {
      console.log("Cannot handle message!");
    }
  }

  toggleOnAir() {
    this.onAir = !this.onAir;
    this.sendOnAir(this.onAir, this.deviceId)
  }

  sendOnAir(onAir, deviceId) {
    if (deviceId === this.deviceId) {
      let messageDict = {
        "command": "send",
        "room": this.selectedRoom.id,
        "message": "onair_" + onAir
      };
      this.sendMessage(messageDict);
    }
  }

  sendMessage(messageDict) {
    messageDict.device = this.readCookie("device_uuid");
    this.websocketClient.send(JSON.stringify(messageDict));
  }
}

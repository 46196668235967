export const logger = {
  info() {
    console.info(...arguments);
  },

  warn() {
    console.warn(...arguments);
  },

  error() {
    console.error(...arguments);
  },

  exception() {
    console.error(...arguments);
  },
};

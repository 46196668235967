import React, {useEffect} from 'react';
import styled from 'styled-components';
import { fadeIn } from './styles/animations';
import { observer } from 'mobx-react-lite';
import { useStore } from './store';
import { Layout } from './components/layout';
import { Main } from './components/main';

import { WorkshopSelect } from './components/workshop-select';

const Snackbar = styled.div`
  position: fixed;
  top: 25rem;
  left: 50%;

  transform: translate(-50%, 0);
  background: grey;
  color: white;
  font-size: 1.6rem;
  padding: 1rem;
  transition: all 200ms;
  pointer-events: none;
  width: 50rem;
  max-width: 94vw;
  z-index: 9999;
  animation: ${fadeIn} 200ms;
`;

export const App = observer(() => {
  const store = useStore();

    useEffect(() => {
        fetch(store.settings.server.protocol+store.settings.server.url+'/get_csrf/', {
            credentials: 'include'  // important for cookies to be sent and received
        })
            .then(response => {
                // Handle response and possibly update state/context
                console.log('CSRF token fetched and cookie set by the server');

                const csrftoken = store.readCookie('csrftoken');


                fetch(store.settings.server.protocol+store.settings.server.url+'/api/create_device/', {
                    method: 'POST',
                    headers: {'X-CSRFToken': csrftoken},
                    credentials: 'include'  // This will ensure cookies are sent and received
                })
                    .then(response => response.json())
                    .then(data => {
                        console.log('Device UUID:', data.device_uuid);
                        store.deviceIdUpdate = data.device_uuid;
                        // You can store this UUID in your state or context if needed
                    })
                    .catch(error => console.error('Error:', error));

            })
            .catch(error => console.error('Error fetching CSRF token:', error));

  }, [store]);  // The empty array makes this effect run only on mount

  return (
    <Layout>
      {store.workshopCodeOk ? <Main /> : <WorkshopSelect />}

      {store.snackbars.map(({ id, content }, i) => (
        <Snackbar key={id} style={{ transform: `translate(-50%, ${0 - 5 * i}rem)` }}>
          {content}
        </Snackbar>
      ))}
    </Layout>
  );
});

export function getCloudServerUrl() {

  const hostAndPort = window.location.host;
  return hostAndPort.split(':')[0];
}

export function getCloudServerPort() {
  return window.location.protocol === 'https:' ? 443 : 80;
}

export function getCloudServerProtocol() {
  return window.location.protocol.concat('//');
}

import React from 'react';

import { MainLayout, TopContent, MainContent, Box, Content } from './main-layout';
import { WorkshopCode } from './workshop-code';


export const WorkshopSelect = () => {

  return (
    <MainLayout>
      <TopContent>
        <h2>Please enter your workshop code: try abcdef</h2>
      </TopContent>

      <MainContent>
        <Content>
          <Box flex column height="100%">
            <Box expanded>
              <WorkshopCode />
            </Box>
          </Box>
        </Content>
      </MainContent>
    </MainLayout>
  );
};

import '../styles/global.js';
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Container = styled.div`
  top: 0;
  height: 100%;
`;

const Main = styled.div`
  height: 100%;
`;

export const Layout = ({ children }) => {
  return (
    <Container>
      <Main>{children}</Main>
    </Container>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

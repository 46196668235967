/*
 * Using websockets
 * */

import { logger } from '../utils/logging'
import ReconnectingWebSocket from "reconnecting-websocket";

export class WebsocketClient {
    constructor(address, storeRef) {
        console.log("Websocket client address", address);
        this.storeRef = storeRef
        this._address = address
        this._socket = null

        this.connectWebsocket()
    }

    invoke(method, params = {}) {
        // add socketio key to all calls made via socketio,
        // so the server can route responses if necessary
        params['socketio'] = true
        logger.info(`[Websocket] ${method}`, params || {})

        const payload = {
            method: method,
            params: params,
            jsonrpc: '2.0',
            id: 2,
        }

        this._socket.emit('jsonrpc', payload)
    }

    connectWebsocket() {

        console.log(`[Websocket] ws_path`, this._address)

        // https://github.com/joewalnes/reconnecting-websocket
        this._socket = new ReconnectingWebSocket(this._address);
        this._socket.debug = true;
        this._socket.timeoutInterval = 5400;
        this._socket.onmessage = (e) => {
            console.log("Received message", e);
            this.storeRef.processMessage(e);
        };

        // Helpful debugging
        this._socket.onopen = function (e) {
          console.log("Connected to socket");
        };

        this._socket.onclose = function (e) {
          console.log("Disconnected from socket");
        };

    }

    send(jsonData) {
        this._socket.send(jsonData);
    }
}



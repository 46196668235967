/**
 * AudiblePresents Client library.
 * Communicates over JSON-RPC to:
 *   - a backend for workshop, room and sound-files
 *
 * Copyright 2021 Max Gate Digital Ltd
 */

/*
 * APIClient
 */
import {RPCClient} from './rpc-client';

export const APIClient = {
  _user_logged_in: false,

  _cloud_server_url: null,
  _cloud_server_port: null,
  _cloud_server_protocol: null,
  _cloud_client: null,
  _user_id: null,
  _workshop_id: null,

  // events
  on_user_change: null,
  on_users_map_change: null,

  _notify_user_change() {
    this.on_user_change?.call(this, {
      user_id: this._user_id,
      user_logged_in: this._user_logged_in,
    });
  },

  /*
   * Allows override RPCClient url
   */
  init_rpc_client(url, port, protocol) {
    this._cloud_server_url = url;
    this._cloud_server_port = port;
    this._cloud_server_protocol = protocol;

    this._cloud_client = RPCClient(this._cloud_server_url, port, protocol);
  },

  async check_workshop_code(params) {
    return await this._cloud_client.invoke('chat.get_workshop', [params,]);
  },

  async check_is_staff() {
    return await this._cloud_client.invoke('chat.get_staff');
  }
};

import {observer, useLocalObservable} from 'mobx-react-lite';
import {useStore} from '../store';
import {
  MainLayout, TopContent, MainContent, TextButton, Box, Div, Content,
} from './main-layout';
import {Button} from './button';
import React from 'react';


export const RoomSelect = observer(() => {
  const store = useStore();
  const state = useLocalObservable(() => ({
    selectedRoomId: null,

    get rooms() {
      return store.workshops[store.workshopCode].rooms;
    },

    get workshop() {
      return store.workshops[store.workshopCode].title;
    },

    selectRoom(room) {
      this.selectedRoomId = room?.id;
    },

    get selectedRoom() {
      return this.rooms.find(room => room.id === this.selectedRoomId)
    },

    get canJoin() {
      return this.selectedRoom;
    },

    joinRoom() {
      if (this.selectedRoom) {
        store.connectToRoom(this.selectedRoomId)
      }
    },
  }));

  // noinspection CheckTagEmptyBody
  return (
    <MainLayout>
      <TopContent>
        <h2>Choose the room</h2>
      </TopContent>

      <MainContent>
        <Content>
          <Box flex column height="100%">
            <Box expanded>
              {state.rooms.map((room, index) => (
                <RoomRow
                  key={index}
                  room={room}
                  selected={state.selectedRoom === room}
                  onSelect={() => state.selectRoom(room)}
                />
              ))}
              {state.rooms.length === 0 && (
                <>No rooms found.</>
              )}

              <Box mt="2rem">
                <Button disabled={!state.canJoin} onClick={() => state.joinRoom()}>
                  Join Room
                </Button>
              </Box>

            </Box>

          </Box>
        </Content>
      </MainContent>

    </MainLayout>
  );
});

const RoomRow = observer(({room, selected, onSelect}) => {
  // const store = useStore();

  // function save() {
  //   store.settings.save();
  // }

  return (
    <Div selected={selected} onClick={() => onSelect(true)}>

      <TextButton
        className={room.label ? null : 'grey'}
        style={{padding: '.8rem .1rem'}}
      >
        {room.title || <em>is it label</em>}
      </TextButton>
    </Div>
  );
});

import styled from "styled-components";

export { FormRow } from './form-row';

export { TextField } from './text-field';
export { TextArea } from './text-area';

export const Form = styled.form`
  max-width: 32rem;
  margin-bottom: 3rem;
`;

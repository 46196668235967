import React from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../store';

import { PlayArea } from './play-area';
import { RoomSelect } from "./room-select";

export const Main = observer(() => {
  const store = useStore();

  return <>
    {store.selectedRoom === null ? <RoomSelect/> : <PlayArea/>}
    </>;

});

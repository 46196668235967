import {getCloudServerUrl, getCloudServerProtocol, getCloudServerPort} from '../utils/utils';

export const defaultSettings = {
  server: {
    protocol: getCloudServerProtocol(),
    url: getCloudServerUrl(),
    port: getCloudServerPort(),
  },
  workshops: {},
};

const localSettings = {
  auth: null,
};

const STORAGE_KEY = 'appSettings';

export class Settings {
  server;
  auth;
  workshops;

  constructor() {
    Object.assign(this, clone(defaultSettings), clone(localSettings));
  }

  onSave = null;

  reset() {
    Object.assign(this, clone(defaultSettings));
  }

  restore() {
    try {
      const data = window.localStorage.getItem(STORAGE_KEY);
      if (data) {
        const json = JSON.parse(data);
        deepMerge(this, json);
      }
    } catch (err) {
      console.error(err);
    }
  }

  save() {
    const data = {
      server: this.server,
      auth: this.auth,
      workshops: this.workshops,
    };
    window.localStorage.setItem(STORAGE_KEY, JSON.stringify(data));

    if (this.onSave) {
      this.onSave();
    }
  }

}


/* utilities */
function deepMerge(target, source) {
  // Iterate through `source` properties and if an `Object` set property to merge of `target` and `source` properties
  for (const key of Object.keys(source)) {
    if (source[key] instanceof Object) {
      Object.assign(source[key], deepMerge(target[key] || {}, source[key]));
    }
  }

  // Join `target` and modified `source`
  Object.assign(target || {}, source);
  return target;
}

function clone(source) {
  return JSON.parse(JSON.stringify(source));
}

import React from 'react';

import { observer, useLocalObservable } from 'mobx-react-lite';
import { Form, FormRow, TextField } from './form';
import { useStore } from '../store';
import { Button } from './button';

export const WorkshopCode = observer(() => {
  const store = useStore();
  const state = useLocalObservable(() => {
    const json = localStorage.getItem('workshop_code');
    const storedUser = json ? JSON.parse(json) : null;
    let workshop_code = '';
    if (storedUser) {
      workshop_code = storedUser.workshop_code;
    }
    return {
      workshop_code: workshop_code,
      busy: false,
    };
  });
  const handleChange = (e) => {
    state[e.target.name] = e.target.value;
  };

  async function submit(e) {
    e.preventDefault();

    // get staff status

    try {
      await store.checkIsStaff();
    } finally {
      console.log('checkIsStaff', store.isStaff);
    }
    state.busy = true;

    try {
      const { workshop_code } = state;
      if (!(await store.checkWorkshopCode(workshop_code))) {
        store.showSnackBar("Can't find a workshop with that code.");
      } else {
        localStorage.setItem('rooms', JSON.stringify({ workshop_code }));
      }
    } finally {
      state.busy = false;
    }
  }

  return (
    <>
      <Form onSubmit={submit}>
        <FormRow>
          <TextField
            autoFocus={!state.email}
            type="text"
            name="workshop_code"
            placeholder="Workshop Code"
            value={state.workshop_code}
            onChange={handleChange}
            required
          />
        </FormRow>

        <Button type="submit" disabled={state.busy}>
          {state.busy ? 'Enter Workshop...' : 'Enter Workshop'}
        </Button>
      </Form>
    </>
  );
});

import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { configure } from 'mobx';
import { App } from './App';
import { StoreProvider } from './store';
import { Store } from './store/store';

configure({
  enforceActions: 'never',
});

const store = new Store();

// if (process && process.env.NODE_ENV !== 'production') {
//   window.store = store;
// }

let rootElement = document.getElementById('root');
if (rootElement !== null) {
  ReactDOM.render(
    <React.StrictMode>
      <StoreProvider value={store}>
        <App />
      </StoreProvider>
    </React.StrictMode>,
    rootElement,
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, { forwardRef } from 'react';
import styled from 'styled-components';

const Input = styled.input`
  width: 100%;
  padding: 0.8rem 0 0.8rem 1rem;
  display: block;

  &[type='number'] {
    padding-right: 1rem;
  }
  
  &:hover {
    border: 1px black solid;
  }
  &:focus {
    outline: none;
  }
  &:disabled {
    cursor: default;
    background: #555;
  }
`;

/* eslint-disable-next-line */
export const TextField = forwardRef((props, ref) => <Input {...props} ref={ref} />);

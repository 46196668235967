import { createGlobalStyle } from 'styled-components';
import sanitize from 'sanitize.css/evergreen.css';
import sanitizeForms from 'sanitize.css/forms.evergreen.css';
import sanitizeAssets from 'sanitize.css/assets.css';
import variables from './variables.css';
import globalCSS from './global.css';

export default createGlobalStyle`
  ${sanitize}
  ${sanitizeForms}
  ${sanitizeAssets}
  ${variables}
  ${globalCSS}
`;

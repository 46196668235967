import React from 'react';
import styled from 'styled-components';

const Input = styled.textarea`
  width: 100%;
  height: 12rem;
  padding: 0.8rem 1.2rem;
  display: block;
`;

export const TextArea = (props) => <Input {...props} />;

import React from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import {
  Box,
  MainContent,
  TopContent,
  TextButton,
  TextButtonDiv,
  Content,
  StyledPlayArea
} from "./main-layout";
import {useStore} from "../store";
import {Button} from "./button";


export const PlayArea = observer(() => {
  const store = useStore();
  const state = useLocalObservable(() => ({
    selectedSoundId: null,
    get sounds() {
      return store.sounds;
    },

    get room() {
      return store.selectedRoom;
    },

    get workshop() {
      return store.workshops.length > 0 ? store.workshops[store.workshopCode].title : "";
    },

    playSound(sound) {
      this.selectedSoundId = sound?.id
      store.playSound(sound.id, store.deviceId)
    },

    get selectedSound() {
      return this.sounds.find(sound => sound.id === this.selectedSoundId)
    },

  }));
  return (
    <StyledPlayArea>
      {
        store.roomSelected ? <>
          <TopContent>
            <h2>{state.room.title}</h2>
            <h2>Currently {store.isStaff ? <>
              <Button disabled={!store.canChangeOnAirStatus}
                      onClick={() => store.toggleOnAir()}>{store.onAir ? 'ON' : 'OFF'}-AIR</Button>
            </> : <>{store.onAir ? 'ON' : 'OFF'}-AIR</>
            }</h2>
          </TopContent>
          <MainContent>
            <Content>
              <Box flex row>
                {store.onAir ?
                  <>{state.sounds.map((sound, index) => (

                    <SoundButton
                      key={index.toString()}
                      value={index}
                      sound={sound}
                      playing={sound.playing}
                      loading={sound.loading}
                      me={sound.me}
                      onSelect={() => state.playSound(sound)}
                    />))}{state.sounds.length === 0 && (<>No sounds found.</>)}
                  </>
                  :
                  <>Not currently 'On Air'</>
                }


              </Box>
            </Content>
          </MainContent>
        </> : <>
          <TopContent>
            <h2>. . . . . </h2>
          </TopContent>
        </>
      }
    </StyledPlayArea>
  );
});

const SoundButton = observer(({sound, loading, playing, me, onSelect}) => {

  return (
    <TextButtonDiv $loading={loading}
                   $playing={playing}
                   $me={me}
                   onClick={() => onSelect(true)
                   }>
      <TextButton
        style={{padding: '.8rem .5rem'}}
      >{sound.title}</TextButton>
    </TextButtonDiv>
  );
});

